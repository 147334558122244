// TEST
console.log("script working");

// HEADER
window.addEventListener("scroll", function () {
  var header = document.querySelector("header");
  header.classList.toggle("sticky", window.scrollY > 0);
});


// Define variables
var tabLabels = document.querySelectorAll("#tabs li");
var tabPanes = document.getElementsByClassName("tab-contents");

function activateTab(e) {
  e.preventDefault();

  // Deactivate all tabs
  tabLabels.forEach(function (label, index) {
    label.classList.remove("active");
  });
  [].forEach.call(tabPanes, function (pane, index) {
    pane.classList.remove("active");
  });

  // Activate current tab
  e.target.parentNode.classList.add("active");
  var clickedTab = e.target.getAttribute("href");
  document.querySelector(clickedTab).classList.add("active");
}

// Apply event listeners
tabLabels.forEach(function (label, index) {
  label.addEventListener("click", activateTab);
});